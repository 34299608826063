import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import { css } from "@emotion/react"
import { useIntl } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"

const GuidePage = ({ data }) => {
    const intl = useIntl()
    const content =
        intl.locale === "ja" ? data.allContentfulShoppingGuide.nodes[0] : data.allContentfulShoppingGuide.nodes[1]

    return (
        <Layout>
            <div css={wrapper}>
                <div css={[sectionStyle, firstSection]} id="guide1">
                    <h4>{content.guide1 && content.guide1}</h4>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: content.guide1Desc.childMarkdownRemark.html.replace(/\n/g, "<br>"),
                        }}
                    />
                </div>
                <div css={sectionStyle} id="guide2">
                    <h4>{content.guide2 && content.guide2}</h4>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: content.guide2Desc.childMarkdownRemark.html.replace(/\n/g, "<br>"),
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}
export default GuidePage

const wrapper = css`
    margin: 0 auto 10vh;
    width: 70vw;
    div:nth-last-of-type(1) {
        border-bottom: none;
    }

    @media ${device.sp} {
        width: 80vw;
    }
`
const sectionStyle = css`
    padding: calc(7vh + 60px) 0 7vh;
    margin-top: -60px;
    border-bottom: solid 1px black;

    h4 {
        margin-bottom: 1.4rem;
    }
    a {
        text-decoration: underline;
    }
`
const firstSection = css`
    @media ${device.sp} {
        padding-top: calc(5vh + 60px);
    }
`

export const query = graphql`
    query {
        allContentfulShoppingGuide {
            nodes {
                guide1
                guide1Desc {
                    childMarkdownRemark {
                        html
                    }
                }
                guide2
                guide2Desc {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
`
